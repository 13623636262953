.App {
  font-family: sans-serif;
  background-color: rgb(247,250,252);
  /* font-size: 1.5em; */
}

.text-monospace {
  font-family: font-family-monospace;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-lg,
.text-xl {
  line-height: 1.75rem;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-text-opacity));
}

.text-flat {
   font-size: 12px;
    margin-top: -5px;
}

.font-bold {
  font-weight: 700;
}

.navbar {
  height: 60px;
}

.grey-bar {
  background-color: rgb(240, 240, 240);
  height: 3px;
}

.btn {
  height: 35px;
  font-size: 14px;
  border-radius: 4px;
}

/* textarea {
  font-size: 1.1em;
  width: 70%;
  display: block;
  margin: auto;
} */

.w-full .fullwidth {
  width: 100%;
}

.modal-90w{
  width: 90%;
  max-width: none !important;
}

.container {
  overflow-y: auto;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.container::-webkit-scrollbar {
  display: none;
}

thead {
  font-weight: bold;
  text-align: center;
  border: 2px solid #999;
}

.error {
  color: #922;
  font-size: 1.0em;
  font-family: monospace;
}

.result-container {
  background-color: rgb(255, 255, 255);
}